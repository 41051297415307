<template>
  
  <div class="container">
    
    <Wallet :address="account" :balance="balance"/>
    
    <Loader v-if="isLoading" ishigh="isBig"/>
    
    <div class="row" v-else>
      
      <div class="col s12 l5 z-depth-2">
        <NewNFT :address="account" @success="updateUI"/>
      </div>
      
      <div class="col s12 l6 offset-l1 misNFTSdiv z-depth-2">
        <NFTlist  :nfts="myNfts" :account="account" title="I Miei NFT" type="myNFTs"/>
      </div>

    </div>

    <teleport to="body">
      <WarningModal :isActive="isActive" @close="isActive=false"/>
    </teleport>
  
  </div>
  
</template>

<script>

//Use these 2 composables to instantiate Contract and Web3
// Call this when component mounts the DOM
import { inject, onMounted, ref, watch, watchEffect } from 'vue';
import Loader from '@/components/UI/Loader';
import NFTlist from '@/components/NFTlist';
import NewNFT from '@/components/NewNFT';
import Wallet from '@/components/Wallet';
import WarningModal from '@/components/WarningModal';

export default {
  
  components: { Loader, NFTlist, NewNFT, Wallet, WarningModal },
  props: ['address'],

  setup(props){

    const store = inject('store');
    let account = ref('');
    const isLoading = ref(false);
    const error = ref(null);
    const isActive = ref(false);
    const balance = ref(0);
    const myNfts = ref([]);

    const getAllNFTs = async () => {
      try {
        
        myNfts.value = [];
        const totSupply = await store.state.prince.methods.totalSupply().call();

        if(totSupply > 0){
          
          for(let i = 0; i < totSupply; i++){
            const nft = await store.state.prince.methods.brands(i).call();
            const owner = await store.state.prince.methods.ownerOf(i + 1).call();
            const brandObj = {
              id: nft.id,
              brand: nft.brand,
              logo: nft.logoUrl,
              price: store.state.web3.utils.fromWei(nft.price, 'ether'),
              isForSale: nft.isForSale,
              createdAt: nft.createdAt,
              owner: owner
            }
            
            if(owner == account.value){
              myNfts.value.push(brandObj);
            }

          }
          
        }else{
          console.log("No NFTs in the Contract");
        }

      } catch (err) {
        console.log(err);
      }
    }

    const updateUI = async () => {
      balance.value = store.state.web3.utils.fromWei(await store.state.usdt.methods.balanceOf(account.value).call(), 'ether');
      await getAllNFTs();
    }

    onMounted(async() => {
      try{
        await store.state.initAll();
        account.value = store.state.accounts[0];
        balance.value = store.state.web3.utils.fromWei(await store.state.usdt.methods.balanceOf(account.value).call(), 'ether');
        const disclaimer = localStorage.getItem('disclaimer');
        console.log("disclaimer: ", disclaimer);
        if(disclaimer != 'true'){
          isActive.value = true;
        }
        return getAllNFTs();
      }catch(err){
        console.log(err);
      }

    })

    return { account, balance, isLoading, error, myNfts, updateUI, isActive }
  }
  
}
</script>

<style scoped>
  .fa-ethereum{
    font-size: 20px;
  }
</style>
