<template>
    
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="close">
        
        <SuccessModalContent 
            title="NFT Acquistato!" 
            v-if="isActiveSuccess"
        />

        <div class="modal-content center grey darken-2" v-else>
            
            <h5 class="blue-grey darken-4 white-text pad">
                Compra NFT
            </h5>
            
            <h6 class = "pad white-text left-align">
                Stai per comprare l' NFT {{ name }} (#ID: {{ id }}).<br><br>
                Prezzo: {{ price }} <b class="green-text">USDT</b>
            </h6>

            <CheckBox @toggleCheck="hasAccepted = !hasAccepted"/> 
            
            <div class="col s12 center">
                <Loader v-if="isLoading" />
                <Error :text="error" v-if="error" />
            </div>
            

            <p>
                <BTN 
                    text="COMPRA"
                    :color="isDisabled"
                    @click.prevent="buy"
                />
            </p>            
        
        </div>

    </div>

</template>

<script>
import { ref } from '@vue/reactivity'
import BTN  from '@/components/UI/BTN';
import Success  from '@/components/UI/Success';
import SuccessModalContent  from '@/components/UI/SuccessModalContent';
import Error  from '@/components/UI/Error';
import Loader  from '@/components/UI/Loader';
import CheckBox  from '@/components/UI/CheckBox';
import useBoilerplate from '@/composables/useBoilerplate'

export default {
    
    components: { Loader, BTN, Success, Error, SuccessModalContent, CheckBox },
    props: ['isActive', 'id', 'account', 'price', 'name'],
    emits: ['close'],

    setup(props, context){
        
        const { isLoading, store, error, isDisabled, isActiveSuccess } = useBoilerplate(false);
        isDisabled.value = 'green';

        const hasAccepted = ref(false);
        
        const close = () => {
            error.value = '';
            isLoading.value = false;
            isActiveSuccess.value = false;
            isDisabled.value = 'green';
            context.emit('close');
        }

        const buy = async () => {
            
            if(hasAccepted.value == false){
                return error.value = "Accettare i Termini e Condizioni"
            }
            
            error.value = null;
            isLoading.value = true;
            isDisabled.value = 'disabled';

            try{
                
                const options = {
                    from: props.account 
                }
                
                await store.state.prince.methods.buy(props.id).send(options);
                isLoading.value = false;
                isActiveSuccess.value = true;
            
            }catch(err){
                isLoading.value = false;
                console.log(err.message);
                error.value = err.message.includes("User denied") ? "L' utente non ha firmato la transazione" : err.message;
                isDisabled.value = "green";
            }
            
        }

        return { isLoading, isActiveSuccess, isDisabled, error, close, buy, hasAccepted }
    }
}
</script>

<style scoped>
    .red{
        margin-top: 20px;
    }
    
</style>