<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="close">

        <SuccessModalContent 
            title="NFT sul Mercato!" 
            v-if="isActiveSuccess"
        />

        <div class="modal-content center grey darken-2" v-else>
            
            <h5 class="white-text blue-grey darken-4 pad">
                Vendi NFT
            </h5>
            
            <form @submit.prevent="market" class = "grey darken-2" style="padding: 20px 10px;">
                
                <div class="input-field">
                    <input type="text" v-model="mail" placeholder="E-Mail">
                </div>

                <div class="input-field">
                    <input type="text" v-model="price" placeholder="Prezzo 0.00 USDT">
                </div>

                <Loader v-if="isLoading" />

                <Error :text="error" v-if="error" />

                <Success text="Il tuo NFT sta sul Mercato!" v-if="isSuccess"/>

                <p>
                    <BTN 
                        text="OK" 
                        :color="isDisabled"
                    />
                </p>

            </form>
                        
        </div>
    </div>
</template>

<script>

import useBoilerplate from '@/composables/useBoilerplate'
import { checkMail, checkPrice } from '@/composables/sanitize'
import Loader  from '@/components/UI/Loader';
import BTN  from '@/components/UI/BTN';
import Success  from '@/components/UI/Success';
import SuccessModalContent from '@/components/UI/SuccessModalContent';
import Error  from '@/components/UI/Error';
import { ref } from '@vue/reactivity';

export default {

    components: { Loader, BTN, Success, Error, SuccessModalContent },
    props: ['isActive', 'id', 'account'],
    emits: ['close'],
    
    setup(props, context){
        
        const { isLoading, isSuccess, store, error, isDisabled, isActiveSuccess } = useBoilerplate(false);
        isDisabled.value = 'green';
        const price = ref(0);
        const mail = ref(null);
        
        const close = () => {
            error.value = '';
            isDisabled.value = 'green';
            isLoading.value = false;
            isActiveSuccess.value = false;
            context.emit('close');
        }

        const market = async () => {

            error.value = null;
            isLoading.value = true;
            isDisabled.value= 'disabled';
            
            if(checkPrice(price.value) == false){
                isLoading.value = false;
                error.value = "Il Prezzo non è corretto";
                return isDisabled.value= 'green';
            }

            if(checkMail(mail.value) == false){
                isLoading.value = false;
                error.value = "La mail non è corretta";
                return isDisabled.value= 'green';
            }

            try{
                await store.state.prince.methods.setForSale(props.id, store.state.web3.utils.toWei(price.value, 'ether'), mail.value).send({from: props.account, gas: 500000});
                isLoading.value = false;
                isSuccess.value = true;
                isActiveSuccess.value = true;
            }catch(err){
                isLoading.value = false;
                console.log(err.message);
                error.value = err.message.includes("User denied") ? "L' utente non ha firmato la transazione" : err.message;
                isDisabled.value= 'green';
            }

        }

        return { price, mail, isLoading, isSuccess, isDisabled, error, close, market, isActiveSuccess }
    }
}
</script>

<style scoped>
    .red{
        margin-top: 20px;
    }
</style>