<template>
    
    <router-link 
        :to="{name: 'NFT', params: {account: account, id: brand.id}}"
        v-if="brand.isForSale==true"
    >

        <div class="col s12 z-depth-2 center itemBOX">
            <div class="logo-box">
                <img :src="url" class="thumbLogo" :alt="brand.logo">
            </div>
            <div class="col s12 center">
                <h6 class="blue-grey-text text-darken-4">
                    <b>{{ brand.brand }}</b>
                </h6>
            </div>
            <div class="col s12 center">
                <h6 class="grey-text text-darken-2">
                    {{ brand.price }} <b class="green-text">USDT</b>
                </h6>
            </div>
        </div>

    </router-link>

</template>

<script>
import { computed } from '@vue/runtime-core';
export default {
    props: ['brand', 'account'],
    setup(props){
        const url = computed(() => {
            const baseUrl = "https://tmpr-nft2.cryptocurrency10.com/brands/";
            if(props.brand.id == 10) {
                return baseUrl + "livero.png";
            } else if (props.brand.id == 5) {
                return baseUrl + "payescrow.png";
            } else if (props.brand.id == 11) {
                return baseUrl + "obd.png";
            } else if (props.brand.id == 13) {
                return baseUrl + "brigades.jpg";
            }else {
                return props.brand.logo;
            }
        });
        return { url }
    }
}
</script>

<style scoped>

    .logo-box {
        width: 100%;
        height: 120px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
    }
    .thumbLogo{
        max-width: 150px;
        max-height: 100px;
        margin: auto;
    }
    .z-depth-2{
        padding: 20px !important;
        border: grey 3px solid;
        margin-bottom: 10px;
        border-radius: 20px;
    }

    .itemBOX{
        height: 250px;
    }
</style>