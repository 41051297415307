import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import en from './locales/en';
import es from './locales/es';
import it from './locales/it';

import FlagIcon from 'vue-flag-icon'
import '../src/assets/css/style.css'
import '../src/assets/css/materialize.min.css'
import '../src/assets/js/materialize.min.js'

let text = navigator.language;
let result = text.slice(0, 2);

const i18n = createI18n({

    locale: result, // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: {
        en,
        it,
        es
    } // set locale messages
})


const app = createApp(App).use(router)
app.use(i18n)
app.mount('#app')

