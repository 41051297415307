import Web3 from 'web3';

export const web3Init = async () => {      
    //return new Web3('http://localhost:9545');
    try{

        //New Metamask (it has more privacy)
        if(typeof window.ethereum != undefined){
            await window.ethereum.enable();
            return new Web3(window.ethereum)
        }

        //Old Metamask
        if(typeof window.web3 != undefined){
            return new Web3(window.web3.currentProvider);
        }
        
        
    }catch(error){
        console.log(error.message);
        const message = error.message.includes('window.ethereum') || error.message.includes("Cannot read property") ? "E' Necessario Installare Metamask" : error.message;
        throw new Error(message);
    }
    
}
