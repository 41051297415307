<template>
  <Nav />
  <router-view/>
  <Footer />
</template>
<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import { onMounted, provide } from 'vue';
import state from './store/state';
export default {
  components: { Nav, Footer },
  setup(){
    provide('store', state);
    onMounted(() => M.AutoInit());
  }
}
console.log(navigator.language);
</script>
<style>

</style>
