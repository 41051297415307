<template>
    
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="close">
        
        <SuccessModalContent 
            title="Permesso Accordato" 
            v-if="isActiveSuccess"
        />

        <div class="modal-content center grey darken-2" v-else>
            
            <h5 class="blue-grey darken-4 white-text pad">
                Consenso
            </h5>
            
            <h6 class = "pad left-align white-text">
                Per interagire con questa applicazione, 
                è necessario consentire l' accesso ai tuoi USDT.
            </h6>

            <Loader v-if="isLoading" />

            <Error :text="error" v-if="error" />
            
            <Success text="Permesso Ottenuto" v-if="isSuccess" />

            <p>
                <BTN 
                    text="OK" 
                    :color="isDisabled" 
                    @click.prevent="allow"
                />
            </p>            
        
        </div>

    </div>

</template>

<script>

import Loader  from '@/components/UI/Loader';
import BTN from '@/components/UI/BTN'
import Success from '@/components/UI/Success'
import SuccessModalContent from '@/components/UI/SuccessModalContent'
import Error from '@/components/UI/Error'
import useBoilerplate from '@/composables/useBoilerplate'

export default {
    
    components: { Loader, BTN, Success, Error, SuccessModalContent },
    props: ['isActive', 'amount', 'account'],
    emits: ['close'],

    setup(props, context){

        const { store, isLoading, isSuccess, error, isDisabled, isActiveSuccess } = useBoilerplate(false);
        
        isDisabled.value = 'green';

        const close = () => {
            isLoading.value = false;
            isDisabled.value = 'green';
            isActiveSuccess.value = false;
            error.value = '';
            context.emit('close');
        }

        const allow = async () => {
            
            error.value = null;
            isLoading.value = true;
            isSuccess.value = false;
            isDisabled.value = 'disabled';

            try{
                
                const options = {
                    from: props.account,
                }
                
                const amountToBeApproved = store.state.web3.utils.toWei(String(Math.round(props.amount)), 'ether');
                console.log("---- Amount to be Approved ----");
                console.log(amountToBeApproved);
                console.log("---------------------------------")
                await store.state.usdt.methods.approve(store.state.contractAddress, amountToBeApproved).send(options);
                
                isLoading.value = false;
                isSuccess.value = true;
                isActiveSuccess.value = true;
            
            }catch(err){
                isLoading.value = false;
                console.log(err.message);
                error.value = err.message.includes("User denied") ? "L' utente non ha firmato la transazione" : err.message;
                isDisabled.value = 'green';
            }
            
        }

        return { isLoading, isSuccess, isDisabled, error, close, allow, isActiveSuccess }
    }
}
</script>

<style scoped>
    .red{
        margin-top: 20px;
    }
    
</style>