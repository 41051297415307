<template>
    
    <div class="col s12">

        <Loader v-if="isLoading" />

        <Error :text="error" v-if="error" />
        
        <p>
            <BTN 
                :color="isDisabled" 
                text="VENDI" 
                @click="promptMarket"
            />
        </p>

        <teleport to="body">

            <MarketModal :isActive="isActive" :account="account" :id="token" @close="edit"/>

        </teleport>
    </div>

</template>

<script>

import Error  from '@/components/UI/Error';
import Loader  from '@/components/UI/Loader';
import MarketModal from '@/components/MarketModal'
import BTN from '@/components/UI/BTN'
import useBoilerplate from '@/composables/useBoilerplate'

export default {
    components: { MarketModal, BTN, Error, Loader },
    props: ['token', 'account'],
    emits: ['transferSuccess'],
    setup(props, context){

        const { isActive, error, isLoading, store, isDisabled } = useBoilerplate(false);

        isDisabled.value = "green";

        const promptMarket = async () => {
            
            error.value = null;
            isLoading.value = true;
            isDisabled.value = "disabled";

            try {
                
                //Make Sure that user has enough BNB 2 pay GAS fees 
                const bnbBalance = store.state.web3.utils.fromWei(
                    await  store.state.web3.eth.getBalance(props.account), 'ether'
                );

                if(bnbBalance == 0){
                    isLoading.value = false;
                    isDisabled.value = "green";
                    return error.value = "Il saldo BNB non è sufficiente per pagare il GAS per questa operazione.";
                }

                isDisabled.value = 'green';
                isLoading.value = false;
                return isActive.value=true;

            } catch (err) {
                console.log(err);
                error.value = err.message;
                isDisabled.value = "green";
            }
            
        }

        const edit = () => {
            isActive.value=false;
            context.emit('transferSuccess');
        }

        return { isActive, edit, promptMarket, isLoading, error, isDisabled };
    }
}
</script>