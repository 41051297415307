<template>
    <router-link
        :to="{name: 'Terms'}"
        target="_blank"
    >
        <span class ="light-blue-text text-lighten-3 guide">
             {{ $t("MieiNFT.Leggere Termini e Condizioni") }} <i class="fas fa-question-circle"></i>
        </span>
    </router-link>
</template>

<style scoped>
    .guide{
        cursor: pointer;
        font-size: 12px;
        display: block;
        padding-top: 10px;
    }
</style>
