import { reactive } from 'vue';
import { web3Init } from '@/composables/web3';
import { contractInit } from '@/composables/contract'
import Prince from '@/contracts/Prince';
import ERC20 from '@/contracts/ERC20';

//minter: '0xCdA9E2a4090C3a4C1597962c0A2bF2C2f5abcf59',

const state = reactive({
    
    web3: null,
    prince: null,
    usdt: null,
    accounts: [],
    contractAddress: '0x8009d2D8c278a5Fe06fDd092dF6e7a9288A6572e',
    contractAddressTest: '0xc037B10c367B4e92183BAF5F33425ae3A948Ed24',
    setupError: null,
    async initAll(){
        try{
            this.web3 = await web3Init();
            this.accounts = await this.web3.eth.getAccounts();
            console.log("Accounts: ", this.accounts);
            this.prince = await contractInit(this.web3, Prince);
            this.usdt = await contractInit(this.web3, ERC20);
        }catch(error){
            this.setupError = error.message;
            console.log("SET UP ERROR: ")
            console.log(this.setupError);
        }
    }
    
});

export default { state }; 