<template>
    
    <router-link :to="{name: 'NFT', params: {account: account, id: brand.id}}">

        <div class="col s12 z-depth-2 valign-wrapper">
            <div class="col s2">
                <img :src="url" class = "thumbLogo" :alt="brand.logo">
            </div>
            <div class="col s8 offset-s2">
                <h6 class="grey-text text-darken-2 left-align">
                    <b>{{ brand.brand }}</b>
                </h6>
            </div>
        </div>

    </router-link>

</template>

<script>
import { computed } from '@vue/runtime-core'
import { onMounted } from 'vue';
export default {
    props: ['brand', 'account'],
    setup(props){
        const url = computed(() => {
            const baseUrl = "https://tmpr-nft2.cryptocurrency10.com/brands/";
            if(props.brand.id == 10) {
                return baseUrl + "livero.png";
            } else if (props.brand.id == 5) {
                return baseUrl + "payescrow.png";
            } else if (props.brand.id == 11) {
                return baseUrl + "obd.png";
            } else if (props.brand.id == 13) {
                return baseUrl + "brigades.jpg";
            }else {
                return props.brand.logo;
            }
        });
        onMounted(()=> console.log(props.brand));
        return { url }
    }
}
</script>

<style scoped>
    .thumbLogo{
        max-width: 50px;
        max-height: 50px;
    }
    .s2{
        padding-top: 5px !important;
    }
    .valign-wrapper{
        height: 60px; 
        margin-bottom: 10px;
    }
    .z-depth-2{
        padding: 20px !important;
        border: grey 3px solid;
        margin-bottom: 10px;
        border-radius: 20px;
    }
</style>