<template>
    
    <div class="col s12">

        <Loader v-if="isLoading" />

        <Error :text="error" v-if="error" />
            
        <p>
            <BTN 
                text="Comprare" 
                :color="isDisabled"  
                @click="promptBuy"
            />
        </p>

        <teleport to="body">
            
            <BuyModal :isActive="isActive" :account="account" :price="price" :name="name" :id="token" @close="edit"/>

            <AllowanceModal 
                :isActive="isActiveWarning" 
                :amount="usdtBalanceFixed" 
                :account="account" 
                @close="isActiveWarning=false"
            />

        </teleport>

    </div>

</template>

<script>

import Error  from '@/components/UI/Error';
import Loader  from '@/components/UI/Loader';
import BuyModal from '@/components/BuyModal'
import useBoilerplate from '@/composables/useBoilerplate'
import AllowanceModal from '@/components/AllowanceModal';
import BTN from '@/components/UI/BTN'
import { ref } from '@vue/reactivity';

export default {

    components: { BuyModal, Error, Loader, AllowanceModal, BTN },
    props: ['token', 'account', 'price', 'name'],
    emits: ['transferSuccess'],
    setup(props, context){
        
        const { isActive, error, isLoading, store, isDisabled, isActiveWarning } = useBoilerplate(false);
        
        isDisabled.value = "green";
        const usdtBalanceFixed = ref(0);

        const promptBuy = async () => {
            
            error.value = null;
            isLoading.value = true;
            isDisabled.value = "disabled";

            try {
                
                //Make Sure that user has enough BNB 2 pay GAS fees 
                const bnbBalance = store.state.web3.utils.fromWei(
                    await  store.state.web3.eth.getBalance(props.account), 'ether'
                );

                if(bnbBalance == 0){
                    isLoading.value = false;
                    isDisabled.value = "green";
                    error.value = "Il saldo BNB non è sufficiente per pagare il GAS per questa operazione.";
                    return;
                }

                //Check Allowance
                const allowance = await store.state.usdt.methods.allowance(props.account, store.state.contractAddress).call();
                
                //Convert it to Eth fixed 2
                const allowanceFixed = Number(store.state.web3.utils.fromWei(allowance, 'ether')).toFixed(2);

                console.log("---- ALLOWANCE & PRICE ------");
                console.log(Number(allowanceFixed), Number(props.price));
                console.log("-------------");

                //Check if allowance is NOT Enough
                if(Number(allowanceFixed) < Number(props.price)){
                    
                    //Check Balance in USDT
                    const usdtBalance = await store.state.usdt.methods.balanceOf(props.account).call();
                    usdtBalanceFixed.value = Number(store.state.web3.utils.fromWei(usdtBalance, 'ether')).toFixed(2);
                    
                    console.log("----BALANCE & PRICE ------");
                    console.log(Number(usdtBalanceFixed.value), Number(props.price));
                    console.log("-------------");

                    //If Balance is Enough ask for permission
                    if(Number(usdtBalanceFixed.value) > Number(props.price)){
                        isLoading.value = false;
                        isDisabled.value = "green";
                        return isActiveWarning.value = true;
                    }else{ //Else show error
                        isLoading.value = false;
                        isDisabled.value = "green";
                        return error.value = "Saldo USDT Insufficiente"
                    }
                }

                isDisabled.value = "green";
                isLoading.value = false;
                return isActive.value=true;

            } catch (err) {
                console.log(err);
                error.value = err.message;
                isDisabled.value = "green";
            }
            
        }

        const edit = () => {
            isActive.value = !isActive;
            context.emit('transferSuccess');
        }
        
        return { isActive, isLoading, isActiveWarning, edit, promptBuy, isDisabled, usdtBalanceFixed, error };
    }
}
</script>