<template>
    <div class = "pink lighten-4 red-text pad" >
        <b>{{ text }}</b>
    </div>
</template>

<script>
export default {
    props: ['text']
}
</script>

<style scoped>
    .pink{
        border-radius: 20px !important;
    }
</style>