<template>
    
    <div class="col s12">

        <Loader v-if="isLoading" />

        <Error :text="error" v-if="error" />

        <p>
            <BTN 
                text="Ritira" 
                :color="isDisabled"  
                @click="promptDeMarket"
            />
        </p>
    
        <teleport to="body">
            <DeMarketModal 
                :isActive="isActive" 
                :account="account" 
                :price="price" 
                :name="name" 
                :id="token" 
                @close="edit"
            />
        </teleport>
        
    </div>

</template>

<script>

import Error  from '@/components/UI/Error';
import Loader  from '@/components/UI/Loader';
import DeMarketModal from '@/components/DeMarketModal'
import BTN from '@/components/UI/BTN'
import useBoilerplate from '@/composables/useBoilerplate'

export default {
    
    components: { DeMarketModal, BTN, Error, Loader },
    props: ['token', 'account', 'price', 'name'],
    emits: ['transferSuccess'],

    setup(props, context){

        const { isActive, error, isLoading, store, isDisabled } = useBoilerplate(false);

        isDisabled.value = "green";

        const promptDeMarket = async () => {
            
            error.value = null;
            isLoading.value = true;
            isDisabled.value = "disabled";

            try {
                
                //Make Sure that user has enough BNB 2 pay GAS fees 
                const bnbBalance = store.state.web3.utils.fromWei(
                    await  store.state.web3.eth.getBalance(props.account), 'ether'
                );

                if(bnbBalance == 0){
                    isLoading.value = false;
                    isDisabled.value = "green";
                    return error.value = "Il saldo BNB non è sufficiente per pagare il GAS per questa operazione.";
                }

                isLoading.value = false;
                isDisabled.value = 'green';
                return isActive.value=true;

            } catch (err) {
                console.log(err);
                error.value = err.message;
                isDisabled.value = "green";
            }
            
        }

        const edit = () => {
            isActive.value = !isActive;
            context.emit('transferSuccess');
        }

        return { isActive, edit, promptDeMarket, isLoading, error, isDisabled };
    }
}
</script>