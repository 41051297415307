export const contractInit = async (web3, smartContract) => {
    const networkId = await web3.eth.net.getId();
    console.log(networkId);
    if(networkId != 56 && networkId != 97){
        throw new Error('Please Select the Binance Smart Chain Network in Metamask');
    }
    return new web3.eth.Contract(
        smartContract.abi, 
        smartContract.networks[networkId].address
    );
}
