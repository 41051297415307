<template>
    <div class="col s12 left-align">
        <label for="pushCheck">            
            <input 
                type="checkbox" 
                id="pushCheck"
                @click="toggleCheck"
            >
            <span class = "white-text">{{ $t("MieiNFT.Accetto Termini e Condizioni del Servizio") }}</span>
        </label><br>
        <HelpTerms />
    </div>
</template>

<script>
import HelpTerms from '@/components/HelpTerms'
export default {
    components: { HelpTerms },
    setup(props, context){
        const toggleCheck = () => context.emit('toggleCheck');
        return { toggleCheck }
    }

}
</script>

<style scoped>

    .left-align{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    [type="checkbox"]+span:before{
        border-color: white !important;
        color: white !important;
    }

  [type="checkbox"]:checked+span:not(.lever):before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent !important;
    border-left: 2px solid transparent !important;
    border-right: 2px solid #ffeb3b !important;
    border-bottom: 2px solid #ffeb3b !important;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
</style>
