import { useRouter } from 'vue-router';

const useNavigation = () => {
    const router = useRouter();
    const exit = () => window.open('https://www.cryptocurrency10.com/', '_blank');
    const mailTo = () => window.location.href = "mailto:info@trademarkregister.org";
    const goBack = () => router.go(-1);
    return { exit, mailTo, goBack }
}

export default useNavigation;
