import { createRouter, createWebHistory } from 'vue-router'
import User from '../views/User.vue'
import NFT from '../views/NFT.vue'
import Market from '../views/Market.vue'

const routes = [

  {
    path: '/',
    name: 'User',
    component: User,
    meta: {
      title: 'Registrare la Marca su NFT'
    }
  },
  {
    path: '/market',
    name: 'Market',
    component: Market,
    meta: {
      title: 'Mercato dei Marchi su NFT'
    }
  },
  {
    path: '/nft/:account/:id',
    name: 'NFT',
    component: NFT,
    meta: {
      title: 'Detagli della Marca'
    },
    props: true
  },
  {
    path: '/come-installare-metamask',
    name: 'MetamaskGuide',
    component: () => import('../views/MetamaskGuide.vue'),
    meta: {
      title: 'Come Installare Metamask'
    },
    props: true
  },
  {
    path: '/classificazione-marchi',
    name: 'Nizza',
    component: () => import('../views/Nizza.vue'),
    meta: {
      title: 'Classificazione Marchi di Nizza'
    }
  },
  {
    path: '/clasificacion-niza',
    name: 'Niza',
    component: () => import('../views/Niza.vue'),
    meta: {
      title: 'Clasificación De Niza - 11 edición, versión 2021'
    }
  },
  {
    path: '/collegare-metamask',
    name: 'CollegareMetaMask',
    component: () => import('../views/CollegareMetaMask.vue'),
    meta: {
      title: 'Collegare MetaMask alla smart chain di Binance'
    }
  },
  {
    path: '/como-conectar-metamask',
    name: 'ComoConectarMetaMask',
    component: () => import('../views/ComoConectarMetaMask.vue'),
    meta: {
      title: 'Collegare MetaMask alla smart chain di Binance'
    }
  },
  {
    path: '/metamask-connected',
    name: 'MetaMaskConnected',
    component: () => import('../views/MetaMaskConnected.vue'),
    meta: {
      title: 'Collegare MetaMask alla smart chain di Binance'
    }
  },
  {
    path: '/come-caricare-logo-nft',
    name: 'Pictures',
    component: () => import('../views/Pictures.vue'),
    meta: {
      title: 'Guida al caricamento dei loghi'
    }
  },
  {
    path: '/questions',
    name: 'Questions',
    component: () => import('../views/Questions.vue'),
    meta: {
      title: 'FAQ'
    }
  },
  {
    path: '/termini-e-condizioni',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
    meta: {
      title: 'Terms and Conditions TMPR'
    }
  }

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

export default router
