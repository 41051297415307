export const checkMail = x => {
    const reg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
	if (reg.test(x) == true){
		return true;
	}else {
        console.log("errore email: ", x);
		return false;
	}
}

export const checkPrice = x => {
    if(isNaN(x) == true || x < 1){
        console.log("The Price must be a number: ", x);
        return false;
    }
    return true;
}