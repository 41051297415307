<template>
    <button class="center" :class="color">
        <b class = "white-text">{{text}}</b>
    </button>
</template>

<script>
export default {
    props: ['text', 'color']
}
</script>

<style>

    button{
        height: 60px !important;
        width: 150px !important;
        border-radius: 20px;
        border: 3px solid white;
        cursor: pointer;
    }

    button .disabled{
        color: grey !important;
    }

    button:active {
        background-color: #4caf50 !important;
    }
    
    button:focus {
        background-color: #4caf50 !important;
    }
    
    button:hover{
        transform: scale(1.05);
    }

</style>
