export const cutKey = (key, interval) => {
  if(!key) return;
  let string = '';
  const newKey = [];
  for(let i = 0; i < key.length; i++){
    if(i % interval > 0){
      newKey.push(key[i]);
    }else{
      newKey.push(' ');
      newKey.push(key[i]);
    }
  }
  newKey.forEach(item => {
    string += item;
  })
  return string;
}