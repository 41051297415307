<template>
    
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="close">
        
        <div class="modal-content center grey darken-2">
            
            <h5 class="blue-grey darken-4 white-text pad">
                METAMASK
            </h5>
            
            <h6 class = "pad white-text left-align">
              {{ $t("MieiNFT.NoPeriniteragire") }},
                {{ warning }}
            </h6>

            <p>
                <router-link :to="{name: 'MetamaskGuide'}" target="_blank" class = "light-blue-text darken-4">
                  {{ $t("MieiNFT.Clicca QUI per maggiori informazioni") }}
                </router-link>
            </p>
            <br>
            <p>
                <BTN 
                    text="OK"
                    color="green"
                     @click="close"
                />
            </p>            
        
        </div>

    </div>

</template>

<script>
import BTN from '@/components/UI/BTN'
export default {
    props: ['isActive', 'warning'],
    emits: ['close'],
    components: { BTN },
    setup(props, context){
        
        const close = () => {
            context.emit('close');
        }
        
        return { close }
    }
}
</script>

<style scoped>
    .red{
        margin-top: 20px;
    }

    a{
        cursor: pointer;
    }
    
</style>
