<template>
    
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="close">

        <SuccessModalContent 
            title="Permesso Revocato" 
            v-if="isActiveSuccess"
        />
        
        <div class="modal-content center grey darken-2" v-else>
            
            <h5 class="blue-grey darken-4 white-text pad">
                Ritira Permesso
            </h5>
            
            <h6 class = "pad left-align white-text">
                Stai per revocare a questa applicazione il permesso di accedere ai tuoi USDT.<br><br>
                Di conseguenza, non potrai eseguire nessuna transazione.
            </h6>

            <Loader v-if="isLoading" />

            <Error :text="error" v-if="error"/>
            
            <Success text="Permesso Ritirato" v-if="isSuccess"/>

            <p>
                <BTN 
                    text="OK" 
                    :color="isDisabled" 
                    @click.prevent="disallow"
                />
            </p>            
        
        </div>

    </div>

</template>

<script>

import Loader  from '@/components/UI/Loader';
import BTN from '@/components/UI/BTN'
import Success from '@/components/UI/Success'
import Error from '@/components/UI/Error'
import SuccessModalContent from '@/components/UI/SuccessModalContent'
import useBoilerplate from '@/composables/useBoilerplate'

export default {
    
    components: { Loader, BTN, Success, Error, SuccessModalContent },
    props: ['isActive', 'account'],
    emits: ['close', 'stopped'],

    setup(props, context){

        const { store, isLoading, isSuccess, isDisabled, error, isActiveSuccess } = useBoilerplate(false);
        
        isDisabled.value = "green";

        const close = () => {
            error.value = '';
            isDisabled.value = 'green';
            isLoading.value = false;
            isActiveSuccess.value = false;
            context.emit('close');
        }

        const disallow = async () => {
            
            error.value = null;
            isLoading.value = true;
            isDisabled.value = "disabled";
            
            try{
                
                const options = {
                    from: props.account,
                }
                
                await store.state.usdt.methods.approve(store.state.contractAddress, '0').send(options);
                
                isLoading.value = false;
                context.emit('stopped');
                isActiveSuccess.value = true; 

            }catch(err){
                isLoading.value = false;
                console.log(err.message);
                error.value = err.message.includes("User denied") ? "L' utente non ha firmato la transazione" : err.message;
            }      

        }

        return { isLoading, isSuccess, isDisabled, error, close, disallow, isActiveSuccess }
    }
}
</script>

<style scoped>
    .red{
        margin-top: 20px;
    }
    
</style>