<template>
  <div class="row">
      <div class="col s12 center">
          <h5 class = "grey-text">{{ $t("MieiNFT.No NFTs") }}</h5>
      </div>
  </div>
</template>

<style scoped>
    h5{
        margin-top: 50px;
    }
</style>
