<template>
    <router-link to="/classificazione-marchi" target="_blank">
        <span class ="light-blue-text text-lighten-3 guide">
             {{ $t("MieiNFT.Cosa è il codice attività") }} <i class="fas fa-question-circle"></i>
        </span>
    </router-link>
</template>

<style scoped>
    .guide{
        cursor: pointer;
        font-size: 12px;
    }
</style>
