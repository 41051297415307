<template>
    <div class="modal-content white">
        <h5 class = "center green-text">{{title}}</h5>
        <h1 class = "center">
            <i class="fas fa-check green-text"></i>
        </h1>
        
        <br>
    </div>
</template>

<script>
import BTN from '@/components/UI/BTN'
export default {
    props: ['title', 'icon'],
    components: { BTN },
    emits: ['close'],
    setup(props, context){
        const handleClose = () => context.emit('close');
        return { handleClose } 
    }
}
</script>

<style>

</style>