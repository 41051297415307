<template>
<div class="row">
    <div class="col s12" :class="ishigh">
        <h6 class="blue-grey-text text-darken-3 left-align">Loading...</h6>
        <div class="progress">
            <div class="indeterminate"></div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: ['ishigh']
}
</script>
<style scoped>
    .isBig{
        padding-top: 150px !important;
        padding-bottom: 350px !important;
    }
</style>