<template>
    
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="close">
        
        <SuccessModalContent 
            title="NFT Ritirato dal Mercato!" 
            v-if="isActiveSuccess"
        />

        <div class="modal-content center grey darken-2" v-else>
            
            <h5 class="blue-grey darken-4 white-text pad">
                Togli dal Mercato
            </h5>
            
            <h6 class = "pad white-text left-align">
                Stai per togliere dal mercato l' NFT {{ name }} (#ID: {{ id }})<br><br>
                Prezzo: {{ price }} <b class = "green-text">USDT</b>
            </h6>

            <Loader v-if="isLoading" />

            <Error :text="error" v-if="error" />
            
            <Success text="NFT Tolto dal Mercato" v-if="isSuccess"/>

            <p>
                <BTN 
                    text="OK"
                    :color="isDisabled"
                    @click.prevent="delist"
                />
            </p>            
        
        </div>

    </div>

</template>

<script>

import Loader  from '@/components/UI/Loader';
import BTN  from '@/components/UI/BTN';
import Success  from '@/components/UI/Success';
import SuccessModalContent from '@/components/UI/SuccessModalContent';
import Error  from '@/components/UI/Error';
import useBoilerplate from '@/composables/useBoilerplate'

export default {

    components: { Loader, BTN, Success, Error, SuccessModalContent },
    props: ['isActive', 'id', 'account', 'price', 'name'],
    emits: ['close'],

    setup(props, context){
        
        const { isLoading, isSuccess, store, error, isDisabled, isActiveSuccess } = useBoilerplate(false);
        
        isDisabled.value = 'green';
        
        const close = () => {
            error.value = '';
            isDisabled.value = 'green';
            isLoading.value = false;
            isActiveSuccess.value = false;
            context.emit('close');
        }

        const delist = async () => {
            
            error.value = null;
            isLoading.value = true;
            isDisabled.value = "disabled";

            try{
                
                const options = {
                    from: props.account,  
                    gas: 500000
                }
                
                await store.state.prince.methods.delist(props.id).send(options);
                
                isLoading.value = false;
                isSuccess.value = true;
                isActiveSuccess.value = true;
            
            }catch(err){
                isLoading.value = false;
                console.log(err.message);
                isDisabled.value = "green";
                error.value = err.message.includes("User denied") ? "L' utente non ha firmato la transazione" : err.message;
            }
            
        }

        return { isLoading, isSuccess, isDisabled, error, close, delist, isActiveSuccess }
    }
}
</script>

<style scoped>
    .red{
        margin-top: 20px;
    }
</style>