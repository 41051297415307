import { useRoute, useRouter } from "vue-router";
const { ref, inject } = require("vue");

const useBoilerplate = (loading) => {
    
    const isLoading = ref(loading);
    const error = ref(null);
    const isActive = ref(null);
    const isDisabled = ref(null);
    const isActiveWarning = ref(null);
    const isActiveSuccess = ref(false);
    const isSuccess = ref(false);
    const isVisible = ref(false);
    const router = useRouter();
    const route = useRoute();
    const store = inject('store');
    
    return { 
        isLoading, error, isActive, isSuccess, router,
        route, store, isActiveWarning, isVisible, isDisabled, isActiveSuccess
    }

}

export default useBoilerplate;