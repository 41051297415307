<template>
    <div class="col" :class="cols">
        <p class="blue-grey-text text-darken-2">
            <b>{{ title }}</b>
        </p>
        <h6 class="grey-text text-darken-2">
            {{ value }} <span class = "green-text" v-if="title=='Price'">USDT</span>
        </h6>
    </div>
</template>

<script>
export default {
    props: ['cols', 'title', 'value']
}
</script>

<style>

</style>