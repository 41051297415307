<template>
  <div class="row">
      <div class="col s12 center blue-grey darken-4">
          <h5 class="white-text">{{ title }}</h5>
      </div>
  </div>
  <NoNFTs v-if="allNFTs.length < 1"/>
  <div class="row" v-if="type=='myNFTs'">
      <div class="col s12" v-for="brand in allNFTs" :key="brand.id">
          <SingleNFT :brand="brand" :account="account" />
      </div>
  </div>
  <div class="row" v-if="type=='allNFTs'">
      <div class="col s16 m6 left-align">
          <h6 class="blue-grey-text text-darken-3">Marchi Registrati: {{ 645000 + nfts.length }}</h6>
      </div>
      <div class="col s16 m6 left-align">
          <h6 class="blue-grey-text text-darken-3">Marchi in Vendita: {{ nfts4Sale.length }}</h6>
      </div>

      <div class = "col s12 blue-grey darken-3 divide" ></div>
      
      <div class="col s12 m6 l4" v-for="brand in nfts4Sale" :key="brand.id">
        <SingleNFTmarket :brand="brand" :account="account"/>
      </div>
  </div>
  
</template>

<script>
import NoNFTs from '@/components/NoNFTs';
import SingleNFT from '@/components/SingleNFT';
import SingleNFTmarket from '@/components/SingleNFTmarket';
import { computed, ref } from '@vue/runtime-core';

export default {
    components: { NoNFTs, SingleNFT, SingleNFTmarket },
    props: ['nfts', 'account', 'title', 'type'],
    setup(props){
        const nfts4Sale = computed(() => {
            return props.nfts.filter(item => item.isForSale != false);
        })
        const allNFTs = computed(() => {
            if(props.nfts && props.nfts.length > 0) {
                return props.nfts.sort((a,b) => (a.brand > b.brand) ? -1 : ((b.brand > a.brand) ? 1 : 0))
            }
            return [];
        })
        return { allNFTs, nfts4Sale }
    }
}
</script>

<style scoped>
    .divide{
        height: 2px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>