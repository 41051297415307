<template>
  <header>
    <div class="navbar-fixed">
      <nav class = "nav-wrapper white">
            <div class="container">
              <a 
                class="brand-logo"
                :href="'https://tmpr-nft.cryptocurrency10.com/' + $i18n.locale  + '/'"
              >
                <img 
                        src="@/assets/img/logo.jpg" 
                        alt="TradeMarke - Public Register" 
                        class = 'logo'
                    >
              </a>

              <a href="#" class = "sidenav-trigger btn-flat white right" data-target = "mobile-links"> 
                <i class="fas fa-bars blue-grey-text text-darken-3"></i>
              </a>

              <ul class = "right hide-on-med-and-down topLinks">

                <li> 
                  <a 
                    class = "grey-text text-darken-2"
                    :href="'https://tmpr-nft.cryptocurrency10.com/' + $i18n.locale  + '/'"
                  >
                    <b>{{ $t("nav.home") }}</b>
                  </a> 
                </li>
                
                <li> 
                  <router-link 
                    class = "grey-text text-darken-2"
                    :to="{name: 'User'}"
                  >
                    <b>{{ $t("nav.MieiNFT") }}</b>
                  </router-link> 
                </li>
                
                <li> 
                  <router-link 
                    class = "grey-text text-darken-2"
                    to="/Market"
                  >
                    <b>{{ $t("nav.Mercato") }}</b>
                  </router-link> 
                </li>

                <li v-if="$i18n.locale == 'it'">
                  <router-link
                      class = "grey-text text-darken-2"
                      to="/classificazione-marchi"
                  >
                    <b>{{ $t("nav.Nizza") }}</b>
                  </router-link>
                </li>

                <li v-if="$i18n.locale == 'es'">
                  <router-link
                      class = "grey-text text-darken-2"
                      to="/clasificacion-niza"
                  >
                    <b>{{ $t("nav.Nizza") }}</b>
                  </router-link>
                </li>

                <li v-if="$i18n.locale == 'it'">
                  <router-link
                      class = "grey-text text-darken-2"
                      to="/metamask-connected"
                  >
                    <b>Collegare MetaMask</b>
                  </router-link>
                </li>

                <li v-if="$i18n.locale == 'en'">
                  <router-link
                      class = "grey-text text-darken-2"
                      to="/metamask-connected"
                  >
                    <b>Connect MetaMask</b>
                  </router-link>
                </li>

                <li v-if="$i18n.locale == 'es'">
                  <router-link
                      class = "grey-text text-darken-2"
                      to="/metamask-connected"
                  >
                    <b>Conectar MetaMask</b>
                  </router-link>
                </li>

                <li> 
                  <router-link 
                    class = "grey-text text-darken-2"
                    to="/Questions"
                  >
                    <b>{{ $t("nav.FAQ") }}</b>
                  </router-link> 
                </li>       

                <li> 
                  <a 
                    class = "grey-text text-darken-2"
                    @click="mailTo"
                  >
                    <b>{{ $t("nav.Contattaci") }}</b>
                  </a> 
                </li>         

                <li> 
                  <a 
                    class = "grey-text text-darken-2"
                    @click="exit"
                  >
                    <b>Home CC10</b>
                  </a> 
                </li> 
              
              </ul>
              <div class="cambio-idioma">
                <SwitchLanguage />
              </div>

            </div>
        </nav>
      <div id="google_translate_element">
        <div class="skiptranslate goog-te-gadget" dir="ltr">
          <div id=":0.targetLanguage" style="display: inline;">
          </div>
        </div>
      </div>
    </div>

    <ul class="sidenav blue-grey darken-4" id = "mobile-links">
      <br>
      
      <li>
        <a 
          :href="'https://tmpr-nft.cryptocurrency10.com/' + $i18n.locale  + '/'" 
          @click="closeMenu"
        >
          <i class="fas fa-home white-text mobLinks"></i> 
          <span id = "contactLinkMob" class= 'mobLinks white-text'>Home</span>
        </a>
      </li>

      <li>
        <router-link to="/" @click="closeMenu">
          <i class="fas fa-palette white-text mobLinks"></i> 
          <span id = "contactLinkMob" class= 'mobLinks white-text'>I Miei NFT</span>
        </router-link> 
      </li>

      <li> 
        <router-link to="/market" @click="closeMenu">
          <i class="fas fa-shopping-cart white-text mobLinks"></i> 
          <span id = "homeLinkMob" class = "mobLinks white-text"> Mercato</span>
        </router-link> 
      </li>

      <li> 
        <router-link to="/questions" @click="closeMenu">
          <i class="fas fa-question white-text mobLinks"></i>
          <span class= 'mobLinks white-text'> FAQ</span>
        </router-link> 
      </li>

      <li> 
        <a href="#" @click="mailTo">
          <i class="fas fa-envelope white-text mobLinks"></i>
          <span class= 'mobLinks white-text'> Contattaci</span>
        </a> 
      </li>

      <li> 
        <a href="#" @click="exit">
          <i class="fas fa-sign-out-alt white-text mobLinks"></i>
          <span class= 'mobLinks white-text'> CC10</span>
        </a> 
      </li>

    </ul>

  <teleport to="body">
    
    <SetupErrorModal 
      :warning="store.state.setupError" 
      :isActive="store.state.setupError!=null" 
      @close="store.state.setupError=null"
    />

  </teleport>  
  
  </header>
  

</template>

<script>
import { inject, onMounted } from '@vue/runtime-core';
import SetupErrorModal from '@/components/SetupErrorModal';
import useNavigation from '@/composables/useNavigation';
import SwitchLanguage from '@/components/SwitchLanguage.vue'

export default {
  name: 'Nav',
  components: { SetupErrorModal, SwitchLanguage },
  
  setup() {
    
    const store = inject('store');
    const { exit, mailTo } = useNavigation();

    const closeMenu = () => {
      const nav = document.querySelector('.sidenav');
      nav.classList.add('sidenav-close');
    }
    
    return { exit, store, closeMenu, mailTo };
  },
}
</script>

<style scoped>
    #google_translate_element {
      margin-bottom: 0px;
      margin-top: 0px;
      position: fixed;
      top: 74px;
      right: 16px;
      z-index: -1;
    }
    .navbar{
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 16px;
    }
    
    .logo{
      position: relative !important;
      right: 5vw !important;
      margin-top: 15px;
      width: 6rem !important;
      
    }
    .mobLinks{
      font-size: 20px;
    }

    .sidenav-trigger{
      position: relative !important;
      left: 5vw !important;
    }

    .topLinks{
      position: absolute !important;
      right: 8em !important;
    }

    .cambio-idioma {
      width: 3%;
      position: absolute;
      right: 1em ;
    }


    @media screen and (max-width: 992px) {
      .logo{
        margin-top: 10px;
        margin-left: 10vw;
        width: 6rem !important;
      }

      .cambio-idioma {
        width: 49px !important;
        float: right;
        position: absolute;
        left: 26px !important;
      }
    }

</style>
