<template>
  <div class="footer">
      <div class="row">
          <div class="col s12 center blue-grey darken-4">
              <p class = "white-text">© TradeMark Public Register 2022</p>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .footer{
        max-height: 50px;
        width: 100%;
        margin-top: 30px;
    }
</style>
