<template>

    <div class="row">
      
      <div class="col s12 l7">
        <h5 class="blue-grey-text text-darken-3">
          {{ $t("MieiNFT.Indirizzo") }}
        </h5>
        <h6 class="grey-text text-darken-2">
          {{ myAddress }}
        </h6>
      </div>
      
      <div class="col s12 l3 left-align">
        <h5 class="blue-grey-text text-darken-3">
          {{ $t("MieiNFT.Saldo") }}
        </h5>
        <h6 class="grey-text text-darken-2">
          <b class = "green-text">USDT</b> {{ Math.round(balance * 100) / 100 }} 
        </h6>
      </div>

      <div class="col s12 l2 left-align" v-if="allowance > 0">
        <p>
          <BTN text="Disconnetti" color="red" @click="handleStop" />
        </p>
      </div>

      <teleport to="body">
      
        <StopModal 
          :isActive="isActive" 
          :account="address"  
          @close="isActive=false"
          @stopped="allowance=0"
        />

      </teleport>

    </div>

</template>

<script>
import StopModal from '@/components/StopModal';
import BTN from '@/components/UI/BTN';
import { computed, ref, watch } from '@vue/runtime-core';
import { cutKey } from '@/composables/cut';
import useBoilerplate from '@/composables/useBoilerplate'

export default {
    props:['owner', 'address', 'balance'],
    components: { StopModal, BTN },
    setup(props){

      const { store, isActive } = useBoilerplate();
      const allowance = ref(0);
      const myAddress = computed(() => cutKey(props.address, 4));
      
      const handleStop = async () => {
        try {
          
          //Make Sure that user has enough BNB 2 pay GAS fees 
          const bnbBalance = store.state.web3.utils.fromWei(
              await  store.state.web3.eth.getBalance(props.address), 'ether'
          );

          console.log(bnbBalance);

          if(bnbBalance == 0){
              return M.toast({html: "Il saldo BNB non è sufficiente per pagare il GAS per questa operazione."})
          }

          isActive.value = true;

        } catch (err) {
          console.log(err);
          return;
        }
      }
      watch(()=> props.address, async () => {
        try{
        allowance.value = await store.state.usdt.methods.allowance(props.address, store.state.contractAddress).call();  
        }catch(error){
          console.log(error.message);
        }
      })

      return { myAddress, allowance, isActive, handleStop };
    }
}
</script>

<style scoped>
  .fa-ethereum{
    font-size: 20px;
  }
</style>
