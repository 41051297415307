<template>
    <div class = "light-green lighten-3 green-text pad" >
        <b>{{text}}</b>
    </div>
</template>

<script>
export default {
    props: ['text']
}
</script>

<style scoped>
    .light-green{
        border-radius: 20px;
    }
</style>