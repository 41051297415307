<template>

    <div class="row">
        
        <div class="col s12 center blue-grey darken-4">
            <h5 class="white-text">
              {{ $t("MieiNFT.Crea NFT") }}
            </h5>
        </div>
        
        <div class="col s12 center-align">
            
            <p class = "grey-text text-darken-2 left-align pad">
              {{ $t("MieiNFT.Riempi il modulo") }}
            </p>

            <div class = "grey darken-2 formDiv">
            
                <form @submit.prevent="upload" >
                    
                    <div class="col s12">
                        <div class="input-field">
                            <input type="text" placeholder="Marca" required v-model="brand">
                        </div>
                    </div>

                    <div class="col s12 left-align">
                        <Select @changed="handleCode" />
                        <HelpCode />
                    </div>
                    
                    <div class="col s12 left-align">
                        <div class="file-field input-field">
                            <div class="btn white">
                                <span>
                                    <i class = "far fa-image blue-grey-text text-darken-3"></i>
                                </span>
                                <input type="file" @change="getFile">
                            </div>
                            
                            <div class="file-path-wrapper">
                                <input class="file-path validate" placeholder="Foto" type="text" ref="pictureField">
                            </div>
                        </div>
                        <HelpPic />
                    </div>
                    
                    <CheckBox @toggleCheck="hasAccepted = !hasAccepted"/>
                    
                    <div class="col s12">
                        <Loader v-if="isLoading" />
                        <Error :text="error" v-if="error" />
                        <Success text="NFT Creato!" v-if="isSuccess"/>
                    </div>
                    
                    <p class = "center">
                        <BTN :text="$t('MieiNFT.BTN')" :color="isDisabled"/>
                    </p>

                </form>

            </div>

        </div>

        <teleport to="body">
            <AllowanceModal 
                :isActive="isActive" 
                :amount="usdtBalanceFixed" 
                :account="address" 
                @close="isActive=false"
            />
        </teleport>
    
    </div>
        
</template>

<script>

import { ref } from '@vue/reactivity'
import Loader from '@/components/UI/Loader'
import BTN from '@/components/UI/BTN'
import Success from '@/components/UI/Success'
import Error from '@/components/UI/Error'
import CheckBox from '@/components/UI/CheckBox'
import AllowanceModal from '@/components/AllowanceModal'
import HelpCode from '@/components/HelpCode'
import HelpPic from '@/components/HelpPic'
import Select from '@/components/UI/Select'
import { postPic } from '@/composables/httpCalls'
import useBoilerplate from '@/composables/useBoilerplate'
import { useI18n } from 'vue-i18n'
export default {
    
    props: ['address'],
    components: { Loader, BTN, AllowanceModal, Error, Success, Select, HelpCode, HelpPic, CheckBox },
    emits: ['success'],

    setup(props, context){
        
        const { store, isLoading, error, isSuccess, isActive, isDisabled  } = useBoilerplate(false);
        const { t } = useI18n();

        isDisabled.value = "green";

        const TextBTN = "CREA NFT";
        
        const brand = ref('');
        const usdtBalanceFixed = ref(0);
        const logo = ref(''); //IPFS Ref
        const picture= ref(null);//Actual Picture
        const pictureField = ref(null);//PIC Fiel in the form
        const hasAccepted = ref(false);

        let code = 0;

        const handleCode = value => code = value;

        const createNFT = async () => {
            console.log("CREATING NFT ONCHAIN");

            error.value = null;
            isLoading.value = true;
            isSuccess.value = false;
            
            try {
                
                const options = {
                    from: props.address
                }

                const newBrand = await store.state.prince
                                    .methods
                                    .mintNew(brand.value, logo.value, code)
                                    .send(options);

                console.log("Brand Registration: ", newBrand);

                isLoading.value = false;
                isSuccess.value = true;
                context.emit('success');
                document.querySelector('form').reset();
                
            } catch (err) {
                console.log("Generic NFT Creation Error")
                console.log(err);
                error.value = err.message;
                isLoading.value = false;
                isDisabled.value = "green";
            }
            
        }

        const getFile = ($event) => {
            console.log("Getting FILE");
            //Select file and show selected file in the UI
            const selectedFile = $event.target.files[0];
            pictureField.value.value = selectedFile.name;
            
            //Check the File is of the correct type and max size is 5MB
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
            if(selectedFile && allowedTypes.indexOf(selectedFile.type) >= 0 && selectedFile.size < 5000000){
                picture.value = $event.target.files[0];
                console.log("File Gotten\n\n", picture.value);
            }else{
                picture.value = null;
                console.log("I miei NFT error1");
                error.value = t('MieiNFT.error1');
            }

        }

        const upload = async () => {

            try{

                console.log("UPLOADING PICTURE INTO IPFS");
                console.log("---------------------------");

                if(hasAccepted.value == false){
                    console.log("I miei NFT error2");
                    return error.value = t('MieiNFT.error2');
                }
            
                //Check if there is a picture to upload
                if(!picture.value){
                    console.log("I miei NFT error3");
                    return error.value = t('MieiNFT.error3');
                }

                if(brand.value == ''){
                    console.log("I miei NFT error4");
                    return error.value = t('MieiNFT.error4');
                }

                if(code == '0' || isNaN(code)){
                    console.log("I miei NFT error5");
                    return error.value = t('MieiNFT.error5');
                }

                console.log("1- Inputs are OK");

                //Show Spinner
                isLoading.value = true;
                isSuccess.value = false;
                error.value = null;
                isDisabled.value = 'disabled';
            
                console.log("2- NOW Checking Cripto Balances");

                //Make Sure that user has enough BNB 2 pay GAS fees 
                const bnbBalance = store.state.web3.utils.fromWei(
                    await  store.state.web3.eth.getBalance(props.address), 'ether'
                );

                if(bnbBalance == 0){
                    isLoading.value = false;
                    isDisabled.value = "green";
                    console.log("I miei NFT error6");
                    return error.value = t('MieiNFT.error6');
                }

                //Check Allowance
                const allowance = await store.state.usdt.methods.allowance(props.address, store.state.contractAddress).call();
                
                //Convert it to Eth fixed 2
                const allowanceFixed = Number(store.state.web3.utils.fromWei(allowance, 'ether')).toFixed(2);

                console.log("3- Allowance? ----");
                console.log(Number(allowanceFixed));
                console.log("-------------------");
                //Check if allowance is NOT Enough
                
                if(Number(allowanceFixed) < 199){
                    //Check Balance in USDT
                    const usdtBalance = await store.state.usdt.methods.balanceOf(props.address).call();
                    usdtBalanceFixed.value = Number(store.state.web3.utils.fromWei(usdtBalance, 'ether')).toFixed(2);
                    
                    console.log("4- Balance? ----");
                    console.log(Number(usdtBalanceFixed.value));
                    console.log("-------------------");

                    //If Balance is Enough ask for permission
                    if(Number(usdtBalanceFixed.value) > 199){
                        console.log("5a- USDT is > than 199");
                        isLoading.value = false;
                        isDisabled.value = 'green';
                        return isActive.value = true;
                    }else{ //Else show error
                        console.log("5b- USDT is < than 199");
                        isLoading.value = false;
                        isDisabled.value = 'green';
                        return error.value = t('MieiNFT.error7');
                    }
                    
                }

                //Upload to IPFS
                console.log("6- Now will save file into IPFS");

                //Set up form data
                let formdata = new FormData();
                formdata.append('photo', picture.value);
                formdata.append('id', "hjgjgj");

                //Upload Image
                const url = "https://tmpr-nft2.cryptocurrency10.com/upload";
                const res = await postPic(url, formdata);
                console.log("7- Response is:\n\n", res.status);
                const data = await res.json();
                console.log("8- Data is:\n\n", data);
                //logo.value = data.hash;
                logo.value = data.url;
                console.log("LOGO VALUE:", logo.value);
                return createNFT();

            }catch(err){
                isLoading.value = false;
                console.log(err.message);
                isDisabled.value = 'green';
                console.log("Generic Upload Error");
                return error.value = err.message.includes("User denied") ? "L' utente non ha firmato la transazione" : err.message;
            }
            
        }
        
        return { 
            brand, logo, picture, pictureField, 
            isActive, isSuccess, isLoading, isDisabled, error,
            getFile, usdtBalanceFixed, upload, handleCode, hasAccepted
        };
    }
}
</script>

<style scoped>
    button{
        margin-top: 20px;
    }
    form{
        padding:20px 10px;
    }
    .formDiv{
        border-radius: 10px;
        min-height: 40vh;
    }
</style>
