<template>
  
  <div class="container">
    
    <Wallet :address="account" :balance="balance"/>
    
    <Loader v-if="isLoading" ishigh="isBig"/>

    <div class="row" v-else>
      <div class="col s12 market">
        <NFTlist  :nfts="nfts" :account="account" title="Mercato" type="allNFTs"/>
      </div>
    </div>
  
  </div>
  
</template>

<script>

//Use these 2 composables to instantiate Contract and Web3
// Call this when component mounts the DOM
import { inject, onMounted, ref, watch, watchEffect } from 'vue';
import Loader from '@/components/UI/Loader';
import NFTlist from '@/components/NFTlist';
import NewNFT from '@/components/NewNFT';
import Wallet from '@/components/Wallet';

export default {
  
  components: { Loader, NFTlist, NewNFT, Wallet },
  props: ['address'],

  setup(props){

    const store = inject('store');
    let account = ref('');
    const isLoading = ref(true);
    const error = ref(null);
    const balance = ref(0);
    const nfts = ref([]);

    const getAllNFTs = async () => {
      try {
        nfts.value = [];
        const totSupply = await store.state.prince.methods.totalSupply().call();

        if(totSupply > 0){
          
          for(let i = 0; i < totSupply; i++){
            const nft = await store.state.prince.methods.brands(i).call();
            const owner = await store.state.prince.methods.ownerOf(i + 1).call();
            const brandObj = {
              id: nft.id,
              brand: nft.brand,
              logo: nft.logoUrl,
              price: store.state.web3.utils.fromWei(nft.price, 'ether'),
              isForSale: nft.isForSale,
              createdAt: nft.createdAt,
              owner: owner
            }
            
            nfts.value.push(brandObj);

          }
          
        }else{
          console.log("No NFTs in the Contract");
        }

        console.log(nfts.value);
        isLoading.value = false;

      } catch (err) {
        console.log(err);
        isLoading.value = false;
      }
    }

    const updateUI = async () => {
      balance.value = store.state.web3.utils.fromWei(await store.state.usdt.methods.balanceOf(account.value).call(), 'ether');
      await getAllNFTs();
    }

    onMounted(async() => {
      try{
        await store.state.initAll();
        account.value = store.state.accounts[0];
        balance.value = store.state.web3.utils.fromWei(await store.state.usdt.methods.balanceOf(account.value).call(), 'ether');
        return getAllNFTs();
      }catch(err){
        console.log(err);
      }

    })

    return { account, balance, isLoading, error, nfts, updateUI }

  }
  
}
</script>

<style scoped>
  .fa-ethereum{
    font-size: 20px;
  }

  .market{
    min-height: 450px !important;
  }
</style>
