<template>
    
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="close">
        
        <div class="modal-content center grey darken-2">
            
            <h5 class="blue-grey darken-4 white-text pad">
                {{ $t("warning.AVVERTENZA") }}
            </h5>
            
            <p class = "pad white-text left-align">
              {{ $t("warning.text1") }}
            </p>

            <p class = "pad white-text left-align">
                <b>{{ $t("warning.text2") }}</b>
            </p>

            <p class = "pad light-blue-text text-lighten-3 left-align link" @click="checkBrand">
                https://www.tmdn.org/tmview/#/tmview
            </p>

            <br>
            <p>
                <BTN 
                    :text="$t('warning.boton')"
                    color="green"
                    @click="accept"
                />
            </p>            
        
        </div>

    </div>

</template>

<script>
import useNavigation from '@/composables/useNavigation'
import BTN from '@/components/UI/BTN'
export default {
  
    props: ['isActive', 'warning'],
    emits: ['close'],
    components: { BTN },

    setup(props, context){
        
        const close = () => {
            context.emit('close');
        }

        const accept = () => {
            localStorage.setItem('disclaimer', 'true');
            close();
        }

        const checkBrand = () => {
            window.open('https://www.tmdn.org/tmview/#/tmview', '_blank');
        }
        
        return { close, checkBrand, accept }
    }
}
</script>

<style scoped>
    .red{
        margin-top: 20px;
    }

    .link{
        cursor: pointer;
    }

    a{
        cursor: pointer;
    }
    
</style>
