<template>
    
    <div class="container">
        
        <Loader v-if="isLoading" ishigh="isBig"/>
        
        <div class="row" v-else>
            <br>

            <div class="col s12 blue-grey darken-4">
                <h5 class = "white-text">Dettagli della Marca</h5>
            </div>

            <div class="col s12">

                <DetailsItem title="#ID" :value="brand.id" cols="s12 m3"/>
                <DetailsItem title="Marca" :value="brand.brand" cols="s12 m3"/>
                <DetailsItem title="Registrato il" :value="brand.createdAt" cols="s12 m3"/>
                <DetailsItem title="Codice Attività" :value="brand.code" cols="s12 m3"/>
                <DetailsItem title="Propietario" :value="brand.owner" cols="s12 m6"/>
                <DetailsItem title="Contatto" :value="brand.mail" cols="s12 m6"/>

                <div class="col s12 right-align blue-grey-text">
                    <h5 v-if="brand.isForSale==true">
                        Prezzo: <b>{{ brand.price }} <span class="green-text">USDT</span></b>
                    </h5>
                    <h5 class="blue-grey-text" v-else>
                        Non in Vendita
                    </h5>
                </div>

            </div>

            <div class="col s12 blue-grey darken-4" style="width: 100%; height: 3px; margin-top: 20px; margin-bottom: 30px"></div>

            <div class="col s12 center">
                <MarketBTN v-if="isOwner==true && brand.isForSale==false" :token="brand.id" :account="account" @transferSuccess="getNFT"/>
                <DeMarketBTN v-if="isOwner==true && brand.isForSale==true" :token="brand.id" :name="brand.brand" :price="brand.price" :account="account" @transferSuccess="getNFT"/>
            </div>

            <div class="col s12 center">
                <Error :text="error" v-if="error" />
            </div>

            <div class="col s12 center">
                <BuyBTN 
                    v-if="isOwner==false && brand.isForSale==true" 
                    :token="brand.id" 
                    :name="brand.brand" 
                    :price="brand.price" 
                    :account="account" 
                    @transferSuccess="getNFT"
                />
            </div>
            
            <div class="col s12 center white brandPic">
                <img :src="url" >
            </div>
            
            <div class="col s12 center">
                <RouterLink to="/Market">
                    <BTN text="INDIETRO" color="green"/>
                </RouterLink>
            </div>

        </div>
    
    </div>

</template>

<script>

import Loader from '@/components/UI/Loader';
import MarketBTN from '@/components/MarketBTN';
import DeMarketBTN from '@/components/DeMarketBTN';
import BuyBTN from '@/components/BuyBTN';
import DetailsItem from '@/components/DetailsItem';
import BTN from '@/components/UI/BTN';
import Error from '@/components/UI/Error';
import { reactive, ref } from '@vue/reactivity';
import { computed, inject, onMounted } from '@vue/runtime-core';
import useNavigation from '@/composables/useNavigation'
import { cutKey } from '@/composables/cut'

export default {
    components: { MarketBTN, DeMarketBTN, BuyBTN, BTN, Loader, Error, DetailsItem },
    props: ['id', 'account'],

    setup(props){
        
        const store = inject('store');
        const { goBack } = useNavigation();
        const isLoading = ref(true);
        const error = ref(null);
        const brand = reactive({});
        const isOwner = ref(false);

        const url = computed(() => {
            const baseUrl = "https://tmpr-nft2.cryptocurrency10.com/brands/";
            if(props.id == 10) {
                return baseUrl + "livero.png";
            } else if (props.id == 5) {
                return baseUrl + "payescrow.png";
            } else if (props.id == 11) {
                return baseUrl + "obd.png";
            } else if (props.id == 13) {
                return baseUrl + "brigades.jpg";
            }else {
                return props.brand.logo;
            }
        });
            
        const getDate = ts => {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            const date = new Date(ts);
            let dateString = '';
            dateString += date.getDate() + ' ';
            dateString += months[date.getMonth()] + ' ';
            dateString += date.getFullYear() + ' ';
            dateString += date.getHours() + ':';
            dateString += date.getMinutes() + ':';
            dateString += date.getSeconds() + ' ';
            return dateString;
        }

        const getNFT = async () => {
            try {
                
                const nft = await store.state.prince.methods.brands(props.id - 1).call();
                console.log(nft);
                const owner = await store.state.prince.methods.ownerOf(props.id).call();
                isOwner.value = owner == props.account ? true : false;
                
                brand.id = nft.id
                brand.brand = nft.brand
                brand.logo = nft.logoUrl
                brand.mail = await store.state.prince.methods._contact(props.id).call();
                brand.createdAt = getDate(nft.createdAt * 1000)
                brand.owner = cutKey(owner, 4);
                brand.isForSale = nft.isForSale,
                brand.code = nft.activityCode;
                brand.price = store.state.web3.utils.fromWei(nft.price, 'ether'),
                isLoading.value = false;

            } catch (err) {
                console.log(err);
                error.value = err.message ? err.message : "Error";
                isLoading.value = false;
            }
        }

        onMounted(async() => {
            try{
                await store.state.initAll();
                return getNFT(props.id);
            }catch(err){
                console.log(err);
            }
        })

        return { isLoading, brand, isOwner, getNFT, error, goBack, url }
    }
}
</script>

<style scoped>
    .brandPic{
        margin: 30px auto !important;
        padding: 20px !important;
        border: 1px solid grey;
        border-radius: 20px;
    }
    img{
        max-width: 100%;
    }

    .z{
        width: 100% !important;
        height: 5px !important;
        color: black !important;
    }

</style>